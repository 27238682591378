import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type SearchState = {
    query: string,
    refinementList: {
        [k: string]: Array<string>
    },
    menu: {
        [k: string]: Array<string>
    },
    page: number,
    totalResults?: number
} & any

const slice = createSlice({
    name: 'searchState',
    initialState: {} as SearchState,
    reducers: {
        // Update search state excluding configure key that is not needed in redux
        updateSearch: (state, {payload: {configure, ...rest}}: PayloadAction<SearchState>) => {
            return {...rest, totalResults: state.totalResults}
        },
        setTotal: (state, {payload}: PayloadAction<number>) => {
            state.totalResults = payload;
        }
    }
})

export const {updateSearch, setTotal} = slice.actions

export default slice.reducer
