import axios from 'axios'
import * as Qs from "qs";
import applyCaseMiddleware from 'axios-case-converter';

interface CsrfToken {
    'X-CSRF-Token'?: string
}

function getCsrfToken(): CsrfToken {
    const tokenDom: HTMLMetaElement = document.querySelector("meta[name=csrf-token]")
    return {'X-CSRF-Token': tokenDom?.content}
}

const api = applyCaseMiddleware(axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...getCsrfToken()
    },
    paramsSerializer: {
        serialize: params => Qs.stringify(params, {arrayFormat: 'brackets'})
    }
}));

/**
 * Costumes json type
 */
export interface Costume {
    id: number
    cmsId: number
    seoTitle: string
    additionalInfo: string
    type: string
    family: string
    brand: string
    serie?: string
    marketing_collection?: string
    description: string
    gender: 'male' | 'female' | 'boy' | 'girl'
    original: boolean
    size: string
    collection: string
    period: string | Array<string>
    epoch: number
    jsonImages: {
        frontUrl?: string
        frontKey?: string
        backUrl?: string
        backKey?: string
    }
}

export interface CartItem {
    id: number,
    position: number,
    comment?: string,
    costume: Costume
}

/**
 * Cart properties stored in redux state
 */
export interface Cart {
    id?: number,
    name?: string,
    itemsCount: number
    layout: 'single' | 'multi' | 'small'
    items: CartItem[]
}

const Routes = {
    addItemCostumeCartPath: (id: number) => `/${window.I18n.locale}/costumes/${id}/cart/add_item`,
    deleteItemCostumeCartPath: (id: number) => `/${window.I18n.locale}/costumes/${id}/cart/remove_item`,
    reorderItemCartPath: (id: number) => `/${window.I18n.locale}/costumes/${id}/cart/reorder`,
    commentItemCartPath: (id: number) => `/${window.I18n.locale}/costumes/${id}/cart/comment`,
    clearCartPath: () => `/${window.I18n.locale}/cart/clear`,
    currentCartPath: () => `/${window.I18n.locale}/cart/current`,
}

export default {
    clear: (): Promise<Cart> => api.delete(Routes.clearCartPath()).then((r) => r.data),
    addCostume: (id: number): Promise<Cart> => api.post<Cart>(Routes.addItemCostumeCartPath(id)).then((r) => r.data),
    deleteCostume: (id: number): Promise<Cart> => api.post<Cart>(Routes.deleteItemCostumeCartPath(id)).then((r) => r.data),
    reorderCostume: (id: number, position: number): Promise<Cart> => api.put<Cart>(Routes.reorderItemCartPath(id), {position}).then((r) => r.data),
    commentCostume: (id: number, comment: string): Promise<Cart> => api.put<Cart>(Routes.commentItemCartPath(id), {comment}).then((r) => r.data),
    current: (): Promise<Cart> => api.get<Cart>(Routes.currentCartPath()).then((r) => r.data),
}

