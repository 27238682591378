import * as React from 'react'
import * as Fa from 'react-fontawesome';
import {connectPagination} from "react-instantsearch-dom";
import {range} from 'lodash'

// How many pages to show around current page
const WIDTH = 4

const Pagination = ({currentRefinement, nbPages, refine}) => {
    const changePage = (page) => (e) => {
        e.preventDefault()
        refine(page)
    }
    const goToFirstPage = changePage(1)
    const goToLastPage = changePage(nbPages)
    const previousPage = changePage(currentRefinement - 1)
    const nextPage = changePage(currentRefinement + 1)

    const pages = range(Math.max(1, currentRefinement - WIDTH), 1 + Math.min(currentRefinement + WIDTH, nbPages))

    return <div id="pagination">
        <ul>
            <li className="prev">
                <a href="#" onClick={goToFirstPage}>
                    <Fa tag='i' name='angle-double-left'/>
                </a>
            </li>
            <li className="prev">
                <a href="#" onClick={previousPage}>
                    <Fa tag='i' name='angle-left'/>
                </a>
            </li>

            {pages.map((page) => {
                const current = currentRefinement === page

                if (current) {
                    return <li key={page} className={'page selected'}>
                        <span className={'current'}>{page}</span>
                    </li>
                } else {
                    return (
                        <li key={page} className='page'>
                            <a href="#" onClick={changePage(page)}>
                                {page}
                            </a>
                        </li>
                    );
                }
            })}

            <li className="next">
                <a href="#" onClick={goToLastPage}>
                    <Fa tag='i' name='angle-double-right'/>
                </a>
            </li>
            <li className="next">
                <a href="#" onClick={nextPage}>
                    <Fa tag='i' name='angle-right'/>
                </a>
            </li>
        </ul>
    </div>
}

export default connectPagination(Pagination);