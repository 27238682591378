import * as React from 'react'
import * as Fa from 'react-fontawesome';
import {useRef, useState} from "react";
import {t} from "../../lib/translate";

function SearchForm({close} : {close: (e: any) => void}) {
    const placeholderDefaultText = t('search_placeholder')
    const [query, setQuery] = useState<string>('')
    const [placeholder, setPlaceholder] = useState<string>(placeholderDefaultText)
    const form = useRef<HTMLFormElement>();

    const doSearchIfQuery = () => {
        if (query) form.current.submit()
        else setPlaceholder(placeholderDefaultText)
    }

    return <div className="header-search-content search-visible">
        <form action={`/${window.I18n.locale}/`} method="get" ref={form}>
            <a href="#" id="close-search" onClick={close}/>
            <input type="text" name="query" className="form-control"
                   value={query} placeholder={placeholder}
                   onFocus={() => setPlaceholder('')}
                   onBlur={doSearchIfQuery}
                   onChange={({target}) => setQuery(target.value) }
                   autoComplete="off"/>
        </form>
        <div className="search-outer"/>
    </div>
}

function MenuSearch() {
    const [open, setOpen] = useState<boolean>(false);
    const preventAndChange = (value: boolean) => (e) => {
        e.preventDefault()
        setOpen(value)
    }
    const openForm = preventAndChange(true)
    const closeForm = preventAndChange(false)

    return <React.Fragment>
        <a href="#" id="show-search" onClick={openForm}>
            <Fa name='search' tag='i'/>
        </a>
        {open && <SearchForm close={closeForm}/>}
    </React.Fragment>
}

export default MenuSearch