import * as React from 'react';
import * as Fa from 'react-fontawesome';
import Pusher from "pusher-js";
import withPusher, {PusherProvider} from "react-pusher-hoc";

interface PdfData {
    id: number,
    attached: boolean,
    url?: string
}

const I18n = window.I18n

const DownloadPdfButton = ({attached, url}: PdfData) => {
    const classes = ['sr-button', 'button-1', 'button-small', 'button-block']

    function handleClick(e) {
        if (attached) return; // Do the download
        e.preventDefault();
        window.toastr.warning(I18n.t('carts.index.pdf_not_ready'));
    }

    return <a className={classes.join(' ')} href={url} onClick={handleClick}>
        {attached && <Fa name='file-pdf-o'/>}
        {attached || <Fa name='spinner' spin/>}
        {' '} {I18n.t('carts.index.download_pdf')}
    </a>
}

const mapEventsToProps = {
    mapPropsToValues: props => props, // Initially forward props to component
    events: {
        'pdf.ready': (pdf : PdfData, state : PdfData, props : PdfData) => {
            if (pdf.id !== props.id) return; // Other PDF ready, not this
            window.toastr.info(I18n.t('carts.index.pdf_ready'));
            return pdf // up to date PDF
        },
    }
};

const ConnectedButton = withPusher(mapEventsToProps)(DownloadPdfButton);

const PushNotificationButton = (props) => {
    const pusherClient = new Pusher(window.Annamode.pusherKey, {cluster: 'eu'});
    return <PusherProvider value={pusherClient}>
        <ConnectedButton {...props}/>
    </PusherProvider>
}

export default PushNotificationButton
