import * as React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {InstantSearch} from 'react-instantsearch-dom';
import {useRef} from "react";
import {SearchClient} from "algoliasearch/dist/algoliasearch-lite";
import {useAppDispatch} from "../store";
import {SearchState, setTotal} from "../store/features/searchStateSlice";
import {updateSearch} from 'store/features/searchStateSlice';
import {createURL, saveSearchStateInUrl, useSearchState} from "../lib/searchState";
import {useDidMount} from "beautiful-react-hooks";

/**
 * Required properties for wrapper
 */
export interface AlgoliaCredentials {
    applicationId: string
    searchApiKey: string
    indexName: string
}

type WrapperProperties = React.PropsWithChildren<AlgoliaCredentials & { detail?: boolean }>;

function AlgoliaWrapper({applicationId, children, indexName, searchApiKey, detail = false}: WrapperProperties) {
    const searchClient = algoliasearch(applicationId, searchApiKey);
    const algolia = useRef<SearchClient>(searchClient);
    const dispatch = useAppDispatch();
    const [searchState, setSearchState] = useSearchState(detail)
    const saveSearchState = (s: SearchState) => {
        setSearchState(s); // Store in local component state
        saveSearchStateInUrl(s) // Save in QueryString
        dispatch(updateSearch(s)) // Save in redux
    }

    // Load total number of results
    useDidMount(() => {
        searchClient.initIndex(indexName).search('').then(results => dispatch(setTotal(results.nbHits)))
    })

    return <InstantSearch searchClient={algolia.current}
                          indexName={indexName}
                          searchState={searchState}
                          createURL={createURL}
                          onSearchStateChange={saveSearchState}>
        {children}
    </InstantSearch>
}

export default AlgoliaWrapper