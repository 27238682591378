import {Scope, TranslateOptions} from "i18n-js";

type TranslateFunction = (scope: Scope, options?: TranslateOptions) => string;

/**
 * Main translate wrapper to return translations without prefixing
 * @param scope
 * @param options
 */
export const t: TranslateFunction = (scope: Scope, options?: TranslateOptions) => window.I18n.t(scope, options)

/**
 * Return a prefixed version of JS I18n.t that acts like erb views in rails
 *
 * @param prefix
 */
export const makePrefixedTranslate: (string) => TranslateFunction = (prefix: string) => {
    return (scope: Scope, options?: TranslateOptions) => {
        if (typeof scope == 'string' && scope.charAt(0) === '.') {
            return window.I18n.t(`${prefix}${scope}`, options)
        } else {
            return window.I18n.t(scope, options)
        }
    }
}
