import * as React from 'react';
import {connectHits} from 'react-instantsearch-dom';
import {t} from "lib/translate";
import HitImage from "./HitImage";
import AddToCartOverlay from "./AddToCartOverlay";
import {Columns} from "react-bulma-components";

export interface AlgoliaCostume {
    objectID: number
    code: number
    seoTitle: string
    additionalInfo: string
    type: string
    family: string
    brand: string
    serie?: string
    description: string
    gender: 'male' | 'female' | 'boy' | 'girl'
    original: boolean
    size: string
    collection: string
    period: string | Array<string>
    epoch: number
    images: Array<string>
    image_keys: Array<string>
    front_image: string
    back_image: string
    url: string
}

function CostumeImage({hit}: { hit: AlgoliaCostume }) {
    return <div className="portfolio-media product-media">

        <a href={hit.url} className="thumb-overlay">
            <HitImage publicId={hit.front_image} alt={hit.type}/>
            <HitImage publicId={hit.back_image} alt={hit.type} className={'hover-pic'}/>
        </a>

        <AddToCartOverlay id={hit.objectID} hit={hit}/>
    </div>
}

function CostumeDescription({hit}: { hit: AlgoliaCostume }) {
    return <div className="portfolio-desc">
        <a href={hit.url}>
            <h6 className="portfolio-category subtitle-2">{t('costumes.index.js.period', {name: hit.period})}</h6>
            <h5 className="portfolio-name"><strong>{t('code', {code: hit.code})}</strong></h5>
        </a>
    </div>

}

function CostumeHit({hit}: { hit: AlgoliaCostume }) {
    return <Columns.Column size='one-third'>
        <CostumeImage hit={hit}/>
        <CostumeDescription hit={hit}/>
    </Columns.Column>
}

const CustomHits = ({hits}) => {
    return <Columns>
        {hits.map(costume => (<CostumeHit key={costume.objectID} hit={costume}/>))}
    </Columns>

}

export default connectHits<AlgoliaCostume>(CustomHits);
