import {Cart} from "../api/cartAPI";
import {useSelector} from "react-redux";
import {RootState} from "../store/reducers/rootReducer";
import {useAppDispatch} from "../store";
import {useEffect} from "react";
import {loadCart} from "../store/features/cartSlice";

export default function useCart() {
    const cart: Cart = useSelector((state: RootState) => state.cart)
    const dispatch = useAppDispatch();

    // Load up to date cart on load selector expression will be updated accordingly
    useEffect(() => {
        dispatch(loadCart())
    }, [])

    return cart;
}