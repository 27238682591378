// Main application store
import {combineReducers} from "@reduxjs/toolkit";
import counterSlice from "../features/counterSlice";
import cartSlice from "../features/cartSlice";
import searchStateSlice from "../features/searchStateSlice";

const rootReducer = combineReducers({
    counter: counterSlice,
    cart: cartSlice,
    searchState: searchStateSlice
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer