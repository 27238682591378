import * as React from 'react';
import * as Fa from 'react-fontawesome';
import {useAppDispatch, withRedux} from "store";
import {useSelector} from 'react-redux'
import {increment} from 'store/features/counterSlice';
import {RootState} from "store/reducers/rootReducer";

// Sample redux counter implemented with redux, keep this as reference
const ReduxCounter = () => {
    const value: number = useSelector((state: RootState) => state.counter)
    const dispatch = useAppDispatch()
    const incrementCounter = () => dispatch(increment(1))

    return <div>
        <Fa name='calculator'/> This is my counter: {value}
        {' '}
        <Fa name={'plus'} style={{cursor: 'pointer'}} onClick={incrementCounter}/>
    </div>
}

export default withRedux(ReduxCounter)