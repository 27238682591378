import {ComponentType} from "react";
import * as React from "react";
import {CloudinaryContext} from 'cloudinary-react';

type WithCloudinaryProps = any & {
    cloudName: string
}

/**
 * withCloudinary HOC, needs to be used for any component that will use redux state
 */
export default function withCloudinary(WrappedComponent: ComponentType<WithCloudinaryProps>) {
    return function WithCloudinaryComponent({cloudName, ...props}: WithCloudinaryProps) {
        // Render component wrapped with context
        return <CloudinaryContext cloudName={cloudName} includeOwnBody>
            <WrappedComponent {...props}/>
        </CloudinaryContext>
    }
}
