import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {useDispatch, Provider} from 'react-redux'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import * as React from 'react';
import {ComponentType} from "react";
import rootReducer, {RootState} from "store/reducers/rootReducer";
import {PersistGate} from "redux-persist/integration/react";
import {PersistConfig} from "redux-persist/es/types";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";

const persistConfig: PersistConfig<RootState> = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// Main application store
const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
})

const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
// export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

/**
 * withRedux HOC, needs to be used for any component that will use redux state
 */
export const withRedux = (WrappedComponent: ComponentType<any>) => {
    return function WithReduxComponent(props: any) {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <WrappedComponent {...props}/>
                </PersistGate>
            </Provider>
        )
    }
}

// Export default redux store from here
export default store;