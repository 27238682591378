import * as React from 'react';
import * as Fa from 'react-fontawesome';
import {useAppDispatch, withRedux} from "store";
import {commentItem, deleteCartItem, sortCart, sortItem} from "store/features/cartSlice";
import {Cart, CartItem} from "api/cartAPI";
import {useEffect, useState} from "react";
import {makePrefixedTranslate} from "lib/translate";
import {SafeImage} from "./search/HitImage";
import withCloudinary from "../lib/withCloudinary";
import {costumeUrl} from "./Annamode";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import FormAutenticityToken from "./FormAutenticityToken";
import useCart from "../hooks/useCart";
import {Columns} from "react-bulma-components";
import {isMobile} from "react-device-detect";

const t = makePrefixedTranslate('carts.new')

interface CartItemProps {
    item: CartItem
}

const SortableList = SortableContainer(({children}) => {
    return <Columns multiline breakpoint="mobile">
        {children}
    </Columns>;
});

const SavingCartItem = SortableElement(({item}: CartItemProps) => {
    const periods: Array<string> = typeof item.costume.period == 'string' ? [item.costume.period] : item.costume.period

    return <Columns.Column desktop={{size: 'one-fifth'}} mobile={{size: 'half'}}>
        <div className="portfolio-media">
            <a href={costumeUrl(item.costume)} className="thumb-overlay" target="_blank">
                <SafeImage costume={item.costume} side='front'/>
                <SafeImage costume={item.costume} side='back'/>
            </a>
        </div>

        <div className="portfolio-desc">
            <h6 className="portfolio-category subtitle-2">{t('costumes.show.period')} {periods.join(', ')} </h6>
            <h5 className="portfolio-name"><strong>{t('code', {code: item.costume.cmsId})}</strong></h5>

            <CommentTextArea item={item}/>

            <div className='align-right'>
                <DeleteItemButton item={item}/>
            </div>
        </div>
    </Columns.Column>
})

function DeleteItemButton({item}: CartItemProps) {
    const dispatch = useAppDispatch()
    const removeFromCart = () => dispatch(deleteCartItem(item.costume.id)).then(() => toastr.warning(t('carts.remove_item.cart_item_removed')));

    return <button type='button' onClick={removeFromCart}
                   className='sr-button button-1 button-icon button-mini'>
        <Fa name='trash' tag='i'/>
    </button>
}

function CommentTextArea({item}: CartItemProps) {
    const dispatch = useAppDispatch()
    const [comment, setComment] = useState<string>(item.comment || '')
    const saveComment = () => {
        if (comment !== item.comment) dispatch(commentItem({costumeId: item.costume.id, comment}));
    }
    // Update comment value on item swap and rerender
    useEffect(() => {
        setComment(item.comment || '')
    }, [item.comment])
    return <textarea value={comment} onBlur={saveComment}
                     onChange={({target}) => setComment(target.value)}
                     placeholder={t('carts.edit_item.placeholder')}/>
}

function LayoutSelector({cart}: { cart: Cart }) {
    const [layout, setLayout] = useState<string>(cart.layout || 'single');
    const styles = isMobile ? {display: 'block'} : {}

    return <div style={{marginBottom: '20px'}}>
        <p>{t('.choose_pdf_layout')}</p>

        {['single', 'multi', 'small'].map(l => (<span style={styles} key={l}>
            <input type="radio" value={l} checked={l == layout}
                   onChange={() => setLayout(l)}
                   name="cart[layout]" id={`cart_layout_${l}`}/>
            <label htmlFor={`cart_layout_${l}`}>
                {t(`.layout.${l}`)}
            </label>
        </span>))}
    </div>
}

function CartForm({children, cart}: React.PropsWithChildren<{ cart: Cart }>) {
    const action = cart.id ? `/${window.I18n.locale}/carts/${cart.id}` : `/${window.I18n.locale}/carts`;

    return <form method="POST" action={action}>
        {cart.id && <input type="hidden" name="_method" value="PUT"/>}
        <FormAutenticityToken/>
        {children}
    </form>
}

// Avoid start sorting on FA icons
function shouldCancelDrag(event) {
    return SortableList.defaultProps.shouldCancelStart(event) || event.target.tagName === 'I'
}

const SavingCart = () => {
    const cart: Cart = useCart();
    const dispatch = useAppDispatch();
    const [name, setName] = useState<string>(cart.name || '')

    const rearranged = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            console.debug(`Moving costume ${cart.items[oldIndex].costume.id} (${cart.items[oldIndex].costume.cmsId}) from ${oldIndex} to ${newIndex}`)
            // Client side sort to optimistically update UI
            dispatch(sortCart({oldIndex, newIndex}));
            // Server side sort on fulfilled will reconcile positions with new values
            dispatch(sortItem({
                costumeId: cart.items[oldIndex].costume.id,
                position: newIndex + 1
            }));
        }
    }

    return <CartForm cart={cart}>

        <input type='text' name='cart[name]' value={name} placeholder={t('.name_your_list')}
               onChange={({target}) => setName(target.value)}/>

        <LayoutSelector cart={cart}/>

        <SortableList axis='xy' onSortEnd={rearranged} shouldCancelStart={shouldCancelDrag}
                      pressDelay={isMobile ? 150 : null}>
            {cart.items.map((item) => (
                <SavingCartItem key={item.id} item={item} index={item.position - 1}/>
            ))}
        </SortableList>

        <div className="align-right">
            <a className="sr-button button-1 button-small button-block"
               href={cart.id ? `/${window.I18n.locale}/carts/${cart.id}` : `/${window.I18n.locale}/carts`}>
                {t('.cancel')}
            </a>
            {' '}
            <input type="submit" name="commit" value={t('.save')} className="button-block"/>
        </div>

    </CartForm>
}

// noinspection JSUnusedGlobalSymbols called from react_component
export default withRedux(withCloudinary(SavingCart))
