import {Costume} from "../api/cartAPI";

export interface Annamode {
    periodOrder: { [k: string]: number }
    env: string,
    customesPerPage: number
    cartEnabled: boolean
    pusherKey: string
}

declare global {
    interface Window {
        Annamode: Annamode
    }
}

export const seoTitleWithFallback = (costume: Costume) => costume.seoTitle || costume.type;
export const costumeUrl = (costume: Costume) => `/${window.I18n.locale}/costumes/${costume.id}`
