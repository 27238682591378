import * as React from 'react';
import AlgoliaWrapper, {AlgoliaCredentials} from "./AlgoliaWrapper";
import {Configure, ScrollTo, Stats} from "react-instantsearch-dom";
import HomeFilters, {PeriodOrdering} from "./search/HomeFilters";
import SerpHits from "./search/SerpHits";
import {withRedux} from "../store";
import withCloudinary from "lib/withCloudinary";
import Pagination from './search/Pagination'
import SearchBar from "./search/SearchBar";
import {t} from "../lib/translate";
import {useSelector} from "react-redux";
import {RootState} from "../store/reducers/rootReducer";

type MainSearchProps = AlgoliaCredentials & {
    costumesPerPage: number,
    periodOrder: PeriodOrdering
}

function MainSearch({costumesPerPage, periodOrder, ...props}: MainSearchProps) {
    const totalResults = useSelector((state: RootState) => state.searchState.totalResults)
    return <AlgoliaWrapper {...props}>
        <Configure hitsPerPage={costumesPerPage}/>
        <HomeFilters periodOrder={periodOrder}/>

        <ScrollTo>
            <SearchBar/>

            <Stats translations={{stats: (hits) => t('costumes.total_results', {count: totalResults, hits: hits})}}/>

            <SerpHits/>

            <div className="spacer-small"/>

            <Pagination/>
        </ScrollTo>
    </AlgoliaWrapper>
}

export default withCloudinary(withRedux(MainSearch))
