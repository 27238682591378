import * as React from "react";
import {Image, Transformation} from 'cloudinary-react';
import {Costume} from "../../api/cartAPI";
import {seoTitleWithFallback} from "../Annamode";

type HitImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    publicId: string
    width?: number
    height?: number
}

type ImageSide = 'front' | 'back'

type SafeImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    width?: number
    height?: number
    costume: Costume,
    side: ImageSide
}

function isCloudinary(costume: Costume): boolean {
    return Boolean(costume.jsonImages.frontKey)
}

function getPublicId(costume: Costume, side: ImageSide): string {
    return costume.jsonImages[side == 'front' ? 'frontKey' : 'backKey']
}

function getImageUrl(costume: Costume, side: ImageSide): string {
    return costume.jsonImages[side == 'front' ? 'frontUrl' : 'backUrl']
}

const makeFallbackUrl = (width: number, height: number) => `https://placehold.co/${width}x${height}?text=Missing+Image`

export function SafeImage({width = 400, height = 600, costume, side, ...props}: SafeImageProps) {
    const errorHandler = ({target}) => target.src = makeFallbackUrl(width, height)
    const alt = seoTitleWithFallback(costume)
    const className = side == 'front' ? '' : 'hover-pic'
    const commonProps = {className: className, width: width, height: height, ...props}
    if (isCloudinary(costume)) return <HitImage publicId={getPublicId(costume, side)} {...commonProps}/>
    else return <img src={getImageUrl(costume, side)} onError={errorHandler} alt={alt} {...commonProps}/>
}

export default function HitImage({publicId, alt, width = 400, height = 600, ...props}: HitImageProps) {
    const errorHandler = ({target}) => target.src = makeFallbackUrl(width, height)
    return <Image publicId={publicId}
                  alt={alt} onError={errorHandler}
                  {...props}>
        <Transformation width={width} height={height} crop="thumb"/>
        <Transformation fetchFormat="auto" quality="auto"/>
    </Image>
}
