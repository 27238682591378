import * as React from 'react';
import * as Fa from 'react-fontawesome';
import {useAppDispatch, withRedux} from "store";
import {clearCart} from "store/features/cartSlice";
import {Cart} from "api/cartAPI";
import {unwrapResult} from "@reduxjs/toolkit";
import {makePrefixedTranslate} from "lib/translate";
import {costumeUrl, seoTitleWithFallback} from "./Annamode";
import {SafeImage} from "./search/HitImage";
import useCart from "../hooks/useCart";
import withCloudinary from "../lib/withCloudinary";

interface CartProps {
    cart: Cart
}

const saveCartUrl = () => `/${window.I18n.locale}/carts/new`

const t = makePrefixedTranslate('layouts.menu_cart')

function truncate(str: string, num: number = 30): string {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) return str
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...'
}

const LinkToCart = ({cart}: CartProps) => {
    return <a href={saveCartUrl()} id='show-cart'>
        <Fa name='shopping-cart' tag='i'/>
        <span>{cart.items.length}</span>
    </a>
}

const CartContent = ({cart}: CartProps) => {
    const dispatch = useAppDispatch();
    const clear = () => dispatch(clearCart()).then(unwrapResult).then(() => toastr.info('Cart cleared'))

    if (cart.items.length == 0) return null

    return <div className="header-cart-content">
        {cart.name && <h4 className="cart-title">{cart.name}</h4>}
        <ul className='cart-item-list'>
            {cart.items.map((item) =>
                (<li key={item.position}>
                    <div className="cart-item-image">
                        <a href={costumeUrl(item.costume)}>
                            <SafeImage costume={item.costume} side='front' width={50} height={65}/>
                        </a>
                    </div>
                    <div className="cart-item-desc">
                        <h6 className="product-name uppercase">
                            <a href={costumeUrl(item.costume)}>
                                {seoTitleWithFallback(item.costume)}
                            </a>
                        </h6>
                        <p>{truncate(item.costume.description)}</p>
                        <span className="cart-item-price">
                            {t('code', {code: item.costume.cmsId})}
                        </span>
                    </div>
                </li>)
            )}
        </ul>
        <div className="cart-total">
            <h6 className="cart-total-name">{t('.total_element')}</h6>
            <div className="cart-total-amount">{t('.costumes', {count: cart.items.length})}</div>
        </div>

        <div className="cart-action">
            <a className={'cart-action-cart sr-button button-mini button-3'} onClick={clear}>
                {t('.clear_cart')}
            </a>
            <a href={saveCartUrl()} className={'cart-action-checkout sr-button button-mini button-2'}>
                {t('.save_cart')}
            </a>
        </div>
    </div>
}

const ReactCart = () => {
    const cart = useCart();

    return <React.Fragment>
        <LinkToCart cart={cart}/>
        <CartContent cart={cart}/>
    </React.Fragment>
}

export default withCloudinary(withRedux(ReactCart))
