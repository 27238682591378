import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Cart, default as cartApi} from "api/cartAPI";
import {arrayMoveImmutable} from 'array-move'

export const addCostumeToCart = createAsyncThunk<Cart, number>(
    'cart/addCostume',
    (costumeId: number) => cartApi.addCostume(costumeId)
)

export const loadCart = createAsyncThunk<Cart, void>(
    'cart/get',
    () => cartApi.current()
)

export const clearCart = createAsyncThunk<Cart, void>(
    'cart/clear',
    () => cartApi.clear()
)

export const deleteCartItem = createAsyncThunk<Cart, number>(
    'cart/deleteCostume',
    (costumeId: number) => cartApi.deleteCostume(costumeId)
)

export const sortItem = createAsyncThunk<Cart, { costumeId: number, position: number }>(
    'cart/sortItem',
    ({costumeId, position}) => cartApi.reorderCostume(costumeId, position)
)

export const commentItem = createAsyncThunk<Cart, { costumeId: number, comment: string }>(
    'cart/commentItem',
    ({costumeId, comment}) => cartApi.commentCostume(costumeId, comment)
)

type CartSlice = Cart

const emptyCart: CartSlice = {
    items: [],
    itemsCount: 0,
    layout: 'single'
}

type SortPayload = {
    oldIndex: number,
    newIndex: number
}

// Replace state cart with returned one
const setCart = (s, {payload}: PayloadAction<Cart>) => payload

const slice = createSlice({
    name: 'cart',
    initialState: emptyCart,
    reducers: {
        sortCart: (state, {payload: {oldIndex, newIndex}}: PayloadAction<SortPayload>) => {
            arrayMoveImmutable(state.items, oldIndex, newIndex)
        }
    },
    extraReducers: builder => {
        builder.addCase(loadCart.fulfilled, setCart)
        builder.addCase(addCostumeToCart.fulfilled, setCart)
        builder.addCase(deleteCartItem.fulfilled, setCart)
        builder.addCase(sortItem.fulfilled, setCart)
        builder.addCase(commentItem.fulfilled, setCart)
        builder.addCase(clearCart.fulfilled, () => emptyCart)
    }
})

export const {sortCart} = slice.actions

export default slice.reducer
