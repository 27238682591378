import * as React from 'react';
import {
    Configure,
    connectHits,
    connectMenu,
    connectPagination,
    connectSearchBox,
} from 'react-instantsearch-dom';
import {t} from "lib/translate";
import HitImage from "./HitImage";
import AddToCartOverlay from "./AddToCartOverlay";
import {AlgoliaCostume} from "./SerpHits";
import AlgoliaWrapper, {AlgoliaCredentials} from "../AlgoliaWrapper";
import withCloudinary from "lib/withCloudinary";
import {withRedux} from "store";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";

function RelatedCostumeHit({hit}: { hit: AlgoliaCostume }) {
    return <div className="shop-item" style={{margin: '0 10px'}}>
        <div className='product-media'>
            <a href={hit.url} className="thumb-overlay">
                <HitImage publicId={hit.front_image} alt={hit.type}/>
                <HitImage publicId={hit.back_image} alt={hit.type} className={'hover-pic'}/>
            </a>
            <AddToCartOverlay hit={hit} id={hit.objectID}/>
        </div>

        <div className="product-desc align-center">
            <a href={hit.url}>
                <h6 className="portfolio-category subtitle-2">{t('costumes.index.js.period', {name: hit.period})}</h6>
                <h5 className="portfolio-name"><strong>{t('code', {code: hit.code})}</strong></h5>
            </a>
        </div>
    </div>
}

const RelatedHitsCarousel = connectHits<AlgoliaCostume>(({hits}) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    // Get current costume from url
    const currentCostumeid = window.location.href.match(/\/costumes\/(\d+)/)[1]

    return <Slider className="shop-container" {...settings}>
        {hits
            .filter(hit => hit.objectID != currentCostumeid)
            .map(costume => (<RelatedCostumeHit key={costume.objectID} hit={costume}/>))}
    </Slider>
})

/**
 * In order to have search state applied there must be widgets rendered in current context
 * I returns same widgets used in MainSearch but with no visibile output.
 * Their only purpose is to refine search according to redux state.
 */
function InvisibleFilters() {
    const InvisibleMenu = connectMenu(() => null)
    const InvisibleSearchBox = connectSearchBox(() => null)
    const InvisiblePagination = connectPagination(() => null)
    return <React.Fragment>
        {['period', 'collection', 'family', 'gender'].map(a => <InvisibleMenu key={a} attribute={a}/>)}
        <InvisibleSearchBox/>
        <InvisiblePagination/>
    </React.Fragment>
}

function RelatedHits(props: AlgoliaCredentials) {
    return <AlgoliaWrapper {...props} detail>
        <Configure hitsPerPage={isMobile ? 7 : 16}/>
        <InvisibleFilters/>
        <RelatedHitsCarousel/>
    </AlgoliaWrapper>
}

export default withCloudinary(withRedux(RelatedHits));
