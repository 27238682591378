import * as React from 'react';
import {useAppDispatch, withRedux} from "store";
import {addCostumeToCart} from "store/features/cartSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {t} from "../lib/translate";
import {ComponentType} from "react";

export function withAddToCart(WrappedComponent: ComponentType<any & { id: number | string }>) {
    return function WithAddToCart({id, ...props}: any & { id: number }) {
        const dispatch = useAppDispatch();
        const addToCart = (e) => {
            e.preventDefault();
            dispatch(addCostumeToCart(Number(id))).then(unwrapResult)
                .then(() => toastr.info(t('carts.add_item.cart_item_added')));
        }

        return <WrappedComponent addToCart={addToCart} {...props}/>
    }
}


// Also export plain button with no redux wrapper
export const AddToCart = withAddToCart(props => {
    return <button onClick={props.addToCart}>
        {t('costumes.show.add_to_my_list')}
    </button>
})

export default withRedux(AddToCart)