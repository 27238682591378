import {withAddToCart} from "../AddToCart";
import {t} from "../../lib/translate";
import * as React from "react";
import {AlgoliaCostume} from "./SerpHits";

type AddToCartComponent = React.FunctionComponent<{ id: number | string, hit: AlgoliaCostume } & any>

const AddToCartOverlay: AddToCartComponent = withAddToCart(props => {
    return <div className="add-to-cart-overlay">
        <a href={props.hit.url} onClick={props.addToCart}>
            {t('costumes.show.add_to_my_list')}
        </a>
    </div>
})

export default AddToCartOverlay