import * as React from 'react'
import {ClearRefinements, SearchBox} from "react-instantsearch-dom";
import {Columns} from "react-bulma-components";
import {t} from "lib/translate";

function SearchBar() {
    return <Columns>
        <Columns.Column size="four-fifths">
            <div id='searchbox'>
                <SearchBox translations={{placeholder: t('costumes.index.js.search')}}/>
            </div>
        </Columns.Column>

        <Columns.Column>
            <ClearRefinements clearsQuery translations={{reset: t('costumes.index.js.clear')}}/>
        </Columns.Column>
    </Columns>
}

export default SearchBar
